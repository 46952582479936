/* .whatsapp {
    box-shadow: 4px 4px 9px #999;;
} */

.whatsapp-button-container {
  text-decoration: none;
}

.whatsapp-ripple {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #25D366;
  border-radius: 50%;
  z-index: 0;
  animation: ripple 2s infinite ease-in-out;
}

.whatsapp-ripple.delay-1 {
  animation-delay: 0.5s;
}

.whatsapp-ripple.delay-2 {
  animation-delay: 1s;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}